body{
    margin: 0;
    padding: 0;
    background-color: rgb(242,245,251) !important;
}
  
.Aa7564 {
    background-color: rgb(242,245,251);
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    z-index: 10;
    font-style: normal;
    opacity: 1;
    color: black;
    font-family: Arial, Helvetica, sans-serif;
    cursor: pointer;
}

.Aa7564 .container {
    display: flex;
    flex-direction: column;
    width: fit-content;
    height: fit-content;
}

.Aa7564 .form-content {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Aa7564 .form-content .form-content-top, .Aa7564 .form-content .form-content-middle, .Aa7564 .form-content .form-content-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Aa7564 .form-content .form-content-middle {
    margin-top: 1.5rem;
}

.Aa7564 .form-content .form-content-top .logo {
    width: 25%;
    height: 25%;
}

.Aa7564 .form-content .form-content-top img {
    padding-bottom: .25rem;
    width: 100%;
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}

.Aa7564 .form-content .form-content-top .title {
    color: black;
    font-size: 24px;
    margin-top: 2.5rem;
}

.Aa7564 .form-content .form-content-middle .logo {
    width: 50%;
    height: 50%;
}

.Aa7564 .form-content .form-content-middle img {
    padding-bottom: .25rem;
    width: 100%;
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}

.Aa7564 .form-content .form-content-top .title h4 {
    margin-block: 0;
}

.Aa7564 .form-content .form-content-middle .title {
    color: rgb(144, 183, 249);
    font-size: 34px;
}

.Aa7564 .form-content .form-content-middle .title span {
    color: black;
    font-size: 15px;
}

.Aa7564 .form-content .form-content-middle .button {
    display: flex;
    flex-direction: column;
    color: white;
}

.Aa7564 .form-content .form-content-middle .button button {
    width: 313px;
    font-size: 24px;
    outline: none;
    color: white;
    background-color: rgb(241, 158, 65);
    border-radius: 35px;
    border-width: 0px;
    padding: 14px 16px;
    cursor: pointer;
    font-weight: 700;
    margin-bottom: 0.3rem;
    box-shadow: 0 4px 8px rgba(2, 2, 2, 0.2);
}

.Aa7564 .form-content .form-content-middle .button button:hover {
    background-color: rgb(144, 183, 249);
}

.Aa7564 .form-content .form-content-middle .button .arrow {
    display: inline-block;
    margin-left: 10px;
    opacity: 0;
    animation: blink 2s infinite;
    color: white;
}

@keyframes blink {
    0%, 50%, 100% {
      opacity: 0;
    }
    25%, 75% {
      opacity: 1;
    }
}

.Aa7564 .form-content .form-content-middle .button span {
    display: flex;
    flex-direction: column;
    color: black;
}

.Aa7564 .form-content .form-content-bottom {
    width: 50%;
    color: black;
    line-height: 1.2rem;
    word-spacing: 0.025rem;
    justify-content: center;
    align-items: center;
    text-align: center;
    justify-items: center;
    align-content: center;
    padding-bottom: 1rem;
    font-size: 10px;
    margin-top: 1.5rem;
    font-weight: lighter;
}

.Aa7564 .otp-input-container {
    display: none;
    justify-content: space-between;
}
  
.Aa7564 .otp-input {
    width: 35px;
    height: 35px;
    text-align: center;
    font-size: 15px;
    border: none;
    border-bottom: 1px solid #ccc;
    margin: 0 5px;
    outline: none;
    font-weight: bold;
    color: black;
}

.Aa7564 .otp-input:focus {
    border-bottom-color: rgb(235, 172, 62);
    box-shadow: none;
}

.Aa7564 .warning-msg {
    visibility: hidden;
    opacity: 0;
    margin-top: -1rem;
    color: black;
    font-size: 16px;
    transition: all 0.5s ease-in-out;
}

.Aa7564 .warning-msg.active {
    visibility: visible;
    opacity: 1;
    margin-top: 0.5rem;
}

.Aa7564 .form-content .form-content-middle .input {
    margin-top: 0.1rem;
    position: relative;
    border-color: #000;
    border-style: solid;
    border-radius: 5px;
    border-width: 1.5px;
    padding-top: .3rem;
    padding-bottom: .3rem;
    transition: all 0.3s ease-in-out;
    background-color: white;
}

.Aa7564 .form-content .form-content-middle .input.vibrate {
    animation: shake 0.6s cubic-bezier(.36,.07,.19,.97) both;
}

.Aa7564 .form-content .form-content-middle .input input {
    width: 300px;
    height: 20px;
    font-size: 15px;
    outline: none;
    color: black;
    border-width: 0px;
    text-align: center;
}

.Aa7564 .form-content .form-content-middle .input:focus {
    outline: none;
}

.Aa7564 .form-content .form-content-middle .input input::placeholder {
    color: black;
}


@keyframes shake {
    0% { transform: translateX(0); }
    10%, 90% { transform: translateX(-10px); }
    20%, 80% { transform: translateX(10px); }
    30%, 50%, 70% { transform: translateX(-10px); }
    40%, 60% { transform: translateX(10px); }
    100% { transform: translateX(0); }
}

@media screen and (max-width: 1350px) and (min-width: 650px)
{
    .Aa7564 .form-content .form-content-middle, .Aa7564 .form-content .form-content-bottom {
        margin-top: 2rem;
    }

    .Aa7564 .form-content .form-content-top .logo {
        width: 22.5%;
        height: 22.5%;
    }

    .Aa7564 .form-content .form-content-bottom {
        width: 60%;
        margin-bottom: unset;
    }
}

@media screen and (max-width: 1070px) and (min-width: 864px)
{
    .Aa7564 .form-content .form-content-bottom {
        width: 70%;
    }
}

@media screen and (max-width: 863px) and (min-width: 650px)
{
    .Aa7564 {
        width: 100%;
        padding: .25rem;
        margin-left: auto;
        margin-right: auto;
    }

    .Aa7564 .form-content .form-content-bottom {
        width: 65%;
    }
}

@media screen and (max-width: 650px)
{
    .Aa7564 {
        width: 100vw;
        margin-left: auto;
        margin-right: auto;
    }
    
    .Aa7564 .form-content .form-content-middle {
        margin-top: 1.5rem;
    }

    .Aa7564 .form-content .form-content-top .logo {
        width: 20%;
        height: 20%;
    }

    .Aa7564 .form-content .form-content-bottom {
        width: 70%;
        margin-bottom: unset;
        margin-top: 3rem;
    }

    .Aa7564 .otp-input-container {
        padding: 2%;
    }
      
    .Aa7564 .otp-input {
        width: 40px;
        height: 40px;
        font-size: 16px;
        margin: 0 2px;
    }
}

@media screen and (max-height: 750px)
{
    .Aa7564 {
      height: unset;
    }
}
