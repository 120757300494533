body{
    margin: 0;
    padding: 0;
    background-color: rgb(244,243,252);
    min-height: 100vh;
    /* background: rgb(244,243,252) !important; */
}
  
.Aa1711 {
    height : fit-content;
    background-color: rgb(244,243,252);
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    z-index: 10;
    font-style: normal;
    opacity: 1;
    color: black;
    font-family: Arial, Helvetica, sans-serif;
    cursor: pointer;
}

.Aa1711 .container {
    display: flex;
    flex-direction: column;
    width: fit-content;
    height: fit-content;
}

.Aa1711 .form-content {
    /* height: 100vh; */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Aa1711 .form-content .form-content-top, .Aa1711 .form-content .form-content-middle {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
}

.Aa1711 .form-content .form-content-middle {
    margin-top: 1.5rem;
}

.Aa1711 .form-content .form-content-middle .non-btn{
    padding-top: 0.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    background-color: rgba(235, 235, 251, 0.8);
}

.Aa1711 .form-content .form-content-top .logo {
    width: 100%;
    height: 100%;
}

.Aa1711 .form-content .form-content-top img {
    padding-bottom: .25rem;
    width: 100%;
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}

.Aa1711 .form-content .form-content-top .small-logo {
    width: 10%;
    height: 10%;
}

.Aa1711 .form-content .form-content-top .title {
    color: black;
    font-size: 24px;
    margin-top: 2.5rem;
}

.Aa1711 .form-content .form-content-top .title h4 {
    margin-block: 0;
}

.Aa1711 .form-content .form-content-middle .title {
    color: black;
    font-size: 14px;
}

.Aa1711 .form-content .form-content-middle .title h4 {
    margin-block: 0;
}

.Aa1711 .form-content .form-content-middle .input {
    margin-top: 0.1rem;
    position: relative;
    border-color: #000;
    border-style: solid;
    border-radius: 5px;
    border-width: 1.5px;
    padding-top: .3rem;
    padding-bottom: .3rem;
    transition: all 0.3s ease-in-out;
    background-color: white;
}

.Aa1711 .form-content .form-content-middle .input.vibrate {
    animation: shake 0.6s cubic-bezier(.36,.07,.19,.97) both;
}

.Aa1711 .form-content .form-content-middle .input input {
    width: 265px;
    height: 20px;
    font-size: 15px;
    outline: none;
    color: black;
    border-width: 0px;
    text-align: center;
}

.Aa1711 .form-content .form-content-middle .input:focus {
    outline: none;
}

.Aa1711 .form-content .form-content-middle .input input::placeholder {
    color: black;
}

.Aa1711 .form-content .form-content-middle .button {
    display: flex;
    flex-direction: column;
    color: black;
    margin-top: 0.25rem;
}

.Aa1711 .form-content .form-content-middle .button button {
    width: 300px;
    font-size: 18px;
    outline: none;
    color: black;
    background-color: rgb(235, 172, 62);
    border-radius: 8px;
    border-width: 0px;
    padding: 10px 16px;
    cursor: pointer;
    font-weight: 700;
    margin-bottom: 0.3rem;
    transition: all 0.3s ease-in-out;
}

.Aa1711 .form-content .form-content-middle .button span {
    display: flex;
    flex-direction: column;
    color: black;
}

.Aa1711 .form-content .form-content-middle .button .arrow {
    display: inline-block;
    margin-left: 10px;
    opacity: 0;
    animation: blink 2s infinite;
    color: black;
}

@keyframes blink {
    0%, 50%, 100% {
      opacity: 0;
    }
    25%, 75% {
      opacity: 1;
    }
}


.Aa1711 .otp-input-container {
    display: none;
    justify-content: space-between;
}
  
.Aa1711 .otp-input {
    width: 35px;
    height: 35px;
    text-align: center;
    font-size: 15px;
    border: none;
    border-bottom: 1px solid #ccc;
    margin: 0 5px;
    outline: none;
    font-weight: bold;
    color: black;
}

.Aa1711 .otp-input:focus {
    border-bottom-color: rgb(235, 172, 62);
    box-shadow: none;
}

.Aa1711 .warning-msg {
    visibility: hidden;
    opacity: 0;
    margin-top: -1rem;
    color: black;
    font-size: 16px;
    transition: all 0.5s ease-in-out;
}

.Aa1711 .warning-msg.active {
    visibility: visible;
    opacity: 1;
    margin-top: 0.5rem;
}

@keyframes shake {
    0% { transform: translateX(0); }
    10%, 90% { transform: translateX(-10px); }
    20%, 80% { transform: translateX(10px); }
    30%, 50%, 70% { transform: translateX(-10px); }
    40%, 60% { transform: translateX(10px); }
    100% { transform: translateX(0); }
}

@media screen and (max-width: 1350px) and (min-width: 650px)
{
    .Aa1711 .form-content .form-content-middle {
        margin-top: 2rem;
    }

    .Aa1711 .form-content .form-content-top .logo {
        width: 100%;
        height: 100%;
    }
}

@media screen and (max-width: 863px) and (min-width: 650px)
{
    .Aa1711 {
        width: 100%;
        padding: .25rem;
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (max-width: 650px)
{
    .Aa1711 {
        width: 100vw;
        margin-left: auto;
        margin-right: auto;
    }
    
    .Aa1711 .form-content .form-content-middle {
        margin-top: 1.5rem;
    }

    .Aa1711 .form-content .form-content-top .logo {
        width: 100%;
        height: 100%;
    }

    .Aa1711 .otp-input-container {
        padding: 2%;
    }
      
    .Aa1711 .otp-input {
        width: 40px;
        height: 40px;
        font-size: 16px;
        margin: 0 2px;
    }
}

@media screen and (max-height: 750px)
{
    .Aa1711 {
      height: unset;
    }
}
